@import '../utils/mixins';
@import '../utils/variable';

.navbar.bg-dark {
  background: $black-color !important;
}

.links {
  a {
    @include primary-fonts(18px);
    text-transform: uppercase !important;
    color: $secondary-color;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .active {
    color: $primary-color !important;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      background: $primary-color;
      bottom: -12px;
      left: 0;
      width: 100%;
      height: 3px;
    }
    &::before {
      position: absolute;
      content: '';
      background: $primary-color;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 12px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
    @media (min-width: 320px) and (max-width: 575px) {
      &::after,
      &::before {
        display: none;
      }
    }
  }
}
.tire__finder {
  @include primary-fonts(18px);
  color: $primary-color !important;
  background: transparent;
  padding: 10px 45px;
  border: 2px solid $primary-color;
  transition: all linear 0.3s;
  margin-right: 10px;

  &:hover {
    background: $secondary-color;
    border-color: $secondary-color;
  }
}
