@import '../base/fonts';

@mixin primary-fonts($font_size: 24px, $font_weight: 400) {
  font-family: $primary-font;
  font-size: $font_size;
  font-weight: $font-weight;

  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 21px;
  }
}

@mixin secondary-fonts($font_size: 18px, $font_weight: 400) {
  font-family: $secondary-font;
  font-size: $font_size;
  font-weight: $font-weight;

  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 16px;
  }
}

@mixin button($padding: 7px 15px, $font-size: 16px) {
  font-size: $font-size;
  padding: $padding;
  border: 0;
}
