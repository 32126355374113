@import '../utils/variable';
@import '../utils/mixins';

.create-news {
  margin-top: 15px;

  form {
    width: 400px;
    margin: 0 auto;

    textarea,
    input {
      width: 100%;
      margin-bottom: 10px;
      border: 1px solid $primary-color;
      padding: 4px;
      @include secondary-fonts;
    }
  }
  button {
    @include button;
    background: $primary-color;
    @include secondary-fonts;
  }
}

.dashboard-jobs {
  padding: 15px;
  form {
    input,
    textarea {
      width: 100%;
      margin-bottom: 5px;
      border: 1px solid $dark-color;
      padding: 5px;
    }
    &::placeholder {
      @include primary-fonts;
    }
  }
  button {
    @include button;
    background: $primary-color;
    @include secondary-fonts;
  }
}

.text-justify {
  text-align: justify;
}

.select-product {
  background: $primary-color;
  margin-left: 25px;
  color: $secondary-color;
  border: 1px solid $primary-color;
  text-transform: capitalize;
  z-index: 5;
  @media (min-width: 320px) and (max-width: 575px) {
    display: block;
    margin-left: 0;
    margin-top: 5px;
  }
}

.link {
  padding: 10px 15px;
  margin-bottom: 10px;
  background: $primary-color;
  color: $secondary-color;
}

.tocart {
  position: fixed;
  right: 20px;
  top: 200px;
  padding: 10px;
  background: $primary-color;
  border-radius: 5px;
  color: $secondary-color;
  font-size: 18px;
  cursor: pointer;

  svg {
    font-size: 22px;
  }
  span {
    font-size: 10px;
    padding: 3px;
    background: $secondary-color;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-color;
  }
}

.button {
  border: 0;
  padding: 5px;
  background: $primary-color;
}

.find-ctg {
  text-transform: capitalize;
}

tr {
  @include secondary-fonts(14px);
}
.order-bg {
  background: $primary-color;
  @include secondary-fonts;
}

li {
  @include secondary-fonts(16px);
}
p {
  @include secondary-fonts(16px);
}

.print-area {
  @media (min-width: 320px) and (max-width: 575px) {
    overflow-x: scroll;
  }
}

.news-details {
  h3 {
    @include secondary-fonts(28px, 600);
  }
}

.last-bottom {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
}

.print-area {
  position: relative;
  height: 1000px;
}
