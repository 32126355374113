* {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none !important;
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 0px;
}
body {
  background: url('../../images/body-bg.png') no-repeat;
  background-size: cover;
  font-family: 'Poppins', sans-serif;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  background: #fc4704 !important;
}
